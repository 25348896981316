import React from 'react';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyPaymentSteps';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyAppDownload';
import { PAIDY_HOST, SITE_METADATA } from 'src/constants';
import { StickyNav } from 'src/components';
import { Box } from '@material-ui/core';
import Condition202404 from 'src/components/Campaign/tvcmlaunch_cashback_202404/Condition202404';
import SectionNotes202402 from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionNotes202402';
import CashbackBanner202404 from 'src/components/Campaign/tvcmlaunch_cashback_202404/CashbackBanner202404';
import CashbackTopBanner202404 from 'src/components/Campaign/tvcmlaunch_cashback_202404/CashbackTopBanner202404';
import PaidyBenefits202404 from 'src/components/Campaign/tvcmlaunch_cashback_202404/PaidyBenefits202404';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './tvcmlaunch_cashback_202404.module.scss';

const SEOProps = {
  title: SITE_METADATA.tvcmlaunch_cashback_202404.title,
  description: SITE_METADATA.tvcmlaunch_cashback_202404.description,
};
const NAVS = [
  'キャンペーン概要',
  'ペイディについて',
  'ペイディが選ばれる理由',
  'ご注意事項',
];

export default function TvcmlaunchCashback202404() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter backgroundColor="white">
      <CashbackTopBanner202404 />
      <Box className={styles.ctaWrapper}>
        <LinearCTAButton text="使えるお店を探す" to={`${PAIDY_HOST}/shop/`} />
      </Box>
      <StickyNav navs={NAVS} />
      <Condition202404 id={NAVS[0]} />
      <JulyPayLater
        id={NAVS[1]}
        description="AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。"
      />
      <JulyPaymentSteps />
      <CashbackBanner202404 />
      <PaidyBenefits202404 id={NAVS[2]} title="ペイディが選ばれる理由" />
      <SectionNotes202402 id={NAVS[3]} />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
