import React from 'react';
import { Hidden } from '@material-ui/core';
import Banner from 'src/images/campaign/tvcmlaunch_cashback_202404/banner.jpg';
import BannerSp from 'src/images/campaign/tvcmlaunch_cashback_202404/banner-sp.jpg';
import styles from './CashbackTopBanner202404.module.scss';

export default function CashbackTopBanner202404() {
  return (
    <section>
      <Hidden xsDown>
        <div className={styles.background}>
          <img src={Banner} alt="banner" />
        </div>
      </Hidden>
      <Hidden smUp>
        <img src={BannerSp} width="100%" height="auto" alt="banner-sp" />
      </Hidden>
    </section>
  );
}
